<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form class="dialogForm" size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" label-width="180px" :disabled="method==='view'">
            <el-form-item label="序号" prop="serialNumber">
                <el-input-number  :step="1" :min="1" :max="9999" v-model="inputForm.serialNumber" placeholder="排序号"></el-input-number>
            </el-form-item>
            <el-form-item label="姓名（名称）：" prop="name">
                <el-input v-model.trim="inputForm.name" placeholder="请输入姓名（名称）" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="gender">
                <el-select v-model="inputForm.gender" clearable style="width:100%" placeholder="请选择性别">
                    <el-option
                            v-for="item in genderOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="出生日期：" prop="dateOfBirth">
                <el-date-picker v-model="inputForm.dateOfBirth" type="month" placeholder="请选择出生日期" value-format="yyyy-MM" style="width:100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="职务：" prop="position">
                <el-input v-model.trim="inputForm.position" placeholder="请输入职务" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="职称：" prop="theTitle">
                <el-input v-model.trim="inputForm.theTitle" placeholder="请输入职称" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="专业：" prop="professional">
                <el-input v-model.trim="inputForm.professional" placeholder="请输入专业" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="专长：" prop="specialty">
                <el-input v-model.trim="inputForm.specialty" placeholder="请输入专长" maxlength="200"></el-input>
            </el-form-item>
            <el-form-item label="民族：" prop="national">
                <el-input v-model.trim="inputForm.national" placeholder="请输入民族" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="学历：" prop="educationBackground">
                <el-input v-model.trim="inputForm.educationBackground" placeholder="请输入学历" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
                <el-input v-model.trim="inputForm.phone" placeholder="请输入联系电话" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="承担的工作（贡献）：" prop="contribution">
                <el-input v-model="inputForm.contribution" type="textarea" :rows="2" placeholder="请输入内容" maxlength="500"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick v-show="method != 'view'">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    exhibitionTeamId:"",
                    serialNumber:"",
                    name: '',
                    gender: '',
                    dateOfBirth:"",
                    position:"",
                    theTitle:"",
                    major:"",
                    professional:"",
                    specialty:"",
                    national:"",
                    educationBackground:"",
                    phone:"",
                    contribution:"",
                },
                genderOpt: [
                    {
                        label: '男',
                        value: '0'
                    },
                    {
                        label: '女',
                        value: '1'
                    }
                ],
                dataRule: {
                    name: [
                        {required: true, message: ' 姓名（名称）不能为空', trigger: 'blur'}
                    ],
                    serialNumber: [
                        {required: true, message: ' 序号不能为空', trigger: 'blur'}
                    ],
                    phone: [{validator: this.validator.isMobile, message: ' 请输入正确的手机号', trigger:'blur'}]
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                this.inputForm.id = ""
                this.inputForm.exhibitionTeamId = ""
                if (method === 'add') {
                    this.title = `新建展览团队成员`
                    this.inputForm.exhibitionTeamId = row
                } else if (method === 'edit') {
                    this.title = '编辑展览团队成员'
                } else if (method === 'view') {
                    this.title = '查看展览团队成员'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit' || method === 'view') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })

            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.common.TeamMemberSave
                        let type = "post"
                        this.inputForm.dateOfBirth =this.inputForm.dateOfBirth ==null?"":this.inputForm.dateOfBirth
                        if(this.title == '编辑展览团队成员'){
                            api = this.api.common.TeamMemberUpdate
                            type = "put"
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }else{
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            }
        }
    }
</script>
